import React from "react"
import Layout from "../components/Layout"
import { Box, Image, Text, Flex } from "rebass"

export default function About() {
  return (
    <Layout>
      <Flex flexDirection={["column", "row"]}>
        <Box width={[1, 4 / 7]}>
          <Image
            src="https://peckish.fra1.digitaloceanspaces.com/AboutGreg.jpg"
            mb={[16, 64]}
            sx={{
              width: ["100%"],
            }}
          />
        </Box>
        <Box mb={16} width={[1, 3 / 7]}>
          <Flex pl={[4, 64]} pr={[4, 0]} flexDirection="column">
            <Text
              fontSize={14}
              mb={16}
              sx={{
                color: "mainForeground",
                fontFamily: "body",
                fontWeight: "body",
                lineHeight: "body",
                textTransform: "uppercase",
                letterSpacing: "0.06em",
              }}
            >
              About
            </Text>
            <Text variant="content">
              Over the last eight years I have undertaken numerous freelance
              commissions across four continents and almost 30 countries,
              working both solo and with crew, including projects in Antigua,
              India, Ibiza, Finland, Egypt, Russia, Vietnam, Mexico, Barbados,
              China, Thailand and the USA to name a few. I shoot a mixture of
              lifestyle and sports stills and all the grounds that sit between
              the two, as well as directing and shooting moving images.
            </Text>
            <Text variant="content">
              My clients include, but are not limited to, TUI UK, Nike, Puma,
              The AA, British Gas, Tesco, Team GB, Lego, Deliveroo, HSBC, Vice,
              Jeep, Grosvenor Hotels, Melia Hotels, Tesco, Hawes & Curtis, TM
              Lewin and Ark.
            </Text>
            <Text variant="content">
              I love my job and find nothing more satisfying than seeing an
              excellent concept realised. I am always seeking commissions and
              looking to expand my portfolio so if you have a project or a
              commission you'd like to work with me on, don't hesitate to ask.
            </Text>
            <Text variant="content">Cheers,</Text>
            <Text variant="content">Greg</Text>
            <Text
              fontSize={14}
              mb={16}
              sx={{
                color: "mainForeground",
                fontFamily: "body",
                fontWeight: "body",
                lineHeight: "body",
                textTransform: "uppercase",
                letterSpacing: "0.06em",
              }}
            >
              Awards & Exhibitions
            </Text>
            <Text variant="awards">- 2016 - AOP Open award shortlist</Text>
            <Text variant="awards">- 2016 - AOP Open award exhibition</Text>
            <Text variant="awards">- 2017 - IPF Awards shortlist</Text>
            <Text variant="awards">- 2017 - IPF Awards exhibition</Text>
            <Text variant="awards">- 2018 - Portrait of Britain Winner</Text>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  )
}
